import { onDutyApi } from '@/api/attendance/onDuty/onDutyApi';

export class onDutyService {
    private api = new onDutyApi();

    //提交
    public submit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    } 
    //获取详情 
    public getInfo(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    } 
     //获取列表
     public getList(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    } 
}