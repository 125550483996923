import AttendanceBaseService from '../base/AttendanceBaseService';

export class onDutyApi extends AttendanceBaseService {
    constructor() {
        super("onDuty");
    }

    /**
   * 提交
   */
    public submit(params: any): Promise<any> {
        return this.request('save', this.rest.METHOD_POST, params);
    }
    //获取详细 
    public getInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
    //获取列表
    public getList(params: any): Promise<any> {
        return this.request('list',this.rest.METHOD_GET,params,);
    }
}